
import { Component, Vue } from 'vue-property-decorator';
import ReviewUnknown from '@/components/modals/ReviewUnknown.vue';
import ReviewUser from '@/components/modals/ReviewUser.vue';
import ImageView from '@/components/modals/ImageView.vue';
import ReviewShare from '@/components/modals/ReviewShare.vue';

@Component({
    components: {
        ReviewUnknown,
        ReviewUser,
        ImageView,
        ReviewShare
    },
})

export default class ProductView extends Vue {
    public packageSize = 1;
    public packageColor = 1;
    public petWeight = 1;
    public packageSizeCalculation = 1;
    public dailyAmountCalculation = 225;
    public daysCalculation = 63;
    public counterNumber = 1;
    public price = 520;
    public totalPrice = this.price * this.counterNumber;

    public counterPlusFunction(){
        // if (this.counterNumber <= 3) {
        //     this.counterNumber++;
        // }
        this.counterNumber++;
        this.totalPrice = this.price * this.counterNumber;
    }
    public counterMinusFunction(){
        if (this.counterNumber >= 2) {
            this.counterNumber--;
        }
        this.totalPrice = this.price * this.counterNumber;
    }
}
